.logo-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.logo-box img {
  filter: invert(42%) sepia(15%) saturate(2223%) hue-rotate(203deg) brightness(84%) contrast(84%);
  width: 70px;
  margin-right: 10px;
}

.logo-box label {
  color: #5E5CB2;
  font-size: 3rem;
  font-weight: bold;
  letter-spacing: 0.3rem;
  font-family: 'Righteous', cursive;
  user-select: none;
  cursor: inherit;
}