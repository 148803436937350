div.Policy h2 {
  text-align: center;
}

div.Policy p {
  line-height: 1.4rem;
}

div.Policy .smallText {
  font-size: 0.8rem;
}