div.Created {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #C1E1C1;
}

div.Created main {
  overflow: auto;
  padding: 10px;
}

div.Created .logo-box {
  cursor: pointer;
}

div.Created .link-box {
  margin-bottom: 15px;
}

div.Created .link-box .link-bar {
  border-radius: 5px;
  box-shadow: 3px 3px 3px #489648;
  padding: 14px;
  background-color: #FFFFFF;
  width: 480px;
  overflow: auto;
  -webkit-transition: background-color 1000ms ease;
  -ms-transition: background-color 1000ms ease;
  transition: background-color 1000ms ease;
}
div.Created .link-box .link-bar.active {
  background-color: #bbd4ec;
  -webkit-transition: background-color 100ms ease;
  -ms-transition: background-color 100ms ease;
  transition: background-color 100ms ease;
}

div.Created .link-box .link-bar input {
  border: none;
  outline: none;
  font-size: 1.5rem;
  background-color: transparent;
  box-sizing: border-box;
  width: calc(100% - 40px);
  height: 30px;
  vertical-align: top;
  color: #7F7577;
}
div.Created .link-box .link-bar input::placeholder {
  color: #E4D2D6;
}

div.Created .link-box .link-bar img {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  vertical-align: top;
  cursor: pointer;
  filter: invert(51%) sepia(7%) saturate(382%) hue-rotate(298deg) brightness(89%) contrast(79%);
}

div.Created .desc-box {
  text-align: center;
}

div.Created .desc-box label {
  color: #7F7577;
}

@media (max-width: 480px) {
  div.Created .logo-box img {
      width: 50px;
      margin-right: 6px;
  }

  div.Created .link-box .link-bar {
      border-radius: 3px;
      box-shadow: 2px 2px 2px #489648;
      padding: 10px;
      width: 280px;
  }

  div.Created .link-box .link-bar input {
      width: calc(100% - 29px);
      height: 23px;
  }

  div.Created .link-box .link-bar img {
      width: 23px;
      height: 23px;
      margin-left: 6px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  div.Created .logo-box img {
      width: 60px;
      margin-right: 8px;
  }

  div.Created .link-box .link-bar {
      border-radius: 4px;
      box-shadow: 2.5px 2.5px 2.5px #489648;
      padding: 12px;
      width: 340px;
  }

  div.Created .link-box .link-bar input {
      width: calc(100% - 34px);
      height: 26px;
  }

  div.Created .link-box .link-bar img {
      width: 26px;
      height: 26px;
      margin-left: 8px;
  }
}