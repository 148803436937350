div.Home {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #D8F2F9;
}

div.Home main {
  overflow: auto;
  padding: 10px;
  width: 100%;
}

div.Home .wrapping-box {
    display: flex;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    justify-content: space-around;
    align-items: center;
}

div.Home .wrapping-box .box-title {
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
    text-align: center;
    color: #6c797c;
}

div.Home .wrapping-box .box-title label {
    font-family: 'Tomorrow', sans-serif;
    font-size: 1.8rem;
    font-weight: bold;
}

div.Home .search-box .search-bar {
    border-radius: 5px;
    box-shadow: 3px 3px 3px #C2D9E0;
    padding: 14px;
    background-color: #FFFFFF;
    width: 360px;
    overflow: auto;
    -webkit-transition: background-color 1000ms ease;
    -ms-transition: background-color 1000ms ease;
    transition: background-color 1000ms ease;
}
div.Home .search-box .search-bar.active {
    background-color: #FF6961;
    -webkit-transition: background-color 100ms ease;
    -ms-transition: background-color 100ms ease;
    transition: background-color 100ms ease;
}

div.Home .search-box .search-bar input {
    border: none;
    outline: none;
    font-size: 1.5rem;
    background-color: transparent;
    box-sizing: border-box;
    width: calc(100% - 40px);
    height: 30px;
    vertical-align: top;
    color: #7F7577;
}
div.Home .search-box .search-bar input::placeholder {
    color: #D2D6E4;
}

div.Home .search-box .search-bar img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    vertical-align: top;
    cursor: pointer;
    filter: invert(51%) sepia(7%) saturate(382%) hue-rotate(298deg) brightness(89%) contrast(79%);
}

div.Home .file-box {
    width: 388px;
    display: inline-block;
}

div.Home .file-box .container {
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
}

div.Home .file-box .container .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #acc1c7;
    border-style: dashed;
    background-color: #ebf8fc;
    color: #acc1c7;
    outline: none;
    transition: border .24s ease-in-out;
    cursor: pointer;
}

div.Home .file-box .container .dropzone p {
    margin: 0;
    font-size: 1.5rem;
}

div.Home .upload-box {
    box-sizing: border-box;
    width: 100%;
    max-width: 1080px;
    padding: 30px;
    margin: 0 auto;
}

div.Home .desc-box {
    margin-top: 30px;
    text-align: center;
}

div.Home .desc-box label {
    color: #7F7577;
    cursor: pointer;
}
div.Home .desc-box label:hover {
    text-decoration: underline;
}

@media (max-width: 480px) {
  div.Home .logo-box img {
      width: 50px;
      margin-right: 6px;
  }

  div.Home .search-box .search-bar {
      border-radius: 3px;
      box-shadow: 2px 2px 2px #C2D9E0;
      padding: 10px;
      width: 220px;
  }

  div.Home .search-box .search-bar input {
      width: calc(100% - 29px);
      height: 23px;
  }

  div.Home .search-box .search-bar img {
      width: 23px;
      height: 23px;
      margin-left: 6px;
  }

  div.Home .file-box {
      width: 248px;
      display: inline-block;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  div.Home .logo-box img {
      width: 60px;
      margin-right: 8px;
  }

  div.Home .search-box .search-bar {
      border-radius: 4px;
      box-shadow: 2.5px 2.5px 2.5px #C2D9E0;
      padding: 12px;
      width: 290px;
  }

  div.Home .search-box .search-bar input {
      width: calc(100% - 34px);
      height: 26px;
  }

  div.Home .search-box .search-bar img {
      width: 26px;
      height: 26px;
      margin-left: 8px;
  }

  div.Home .file-box {
      width: 318px;
      display: inline-block;
  }
}

@media (max-width: 960px) {
    div.Home .wrapping-box {
        flex-direction: column;
    }

    div.Home .wrapping-box > div {
        margin: 30px 0;
    }
}