div.File {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eafff2;
}

div.File main {
  overflow: auto;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
  padding: 30px;
}

div.File .logo-box {
  cursor: pointer;
}

div.File .file-box {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4fff8;
  border: 1px solid #d2e5d9;
  border-radius: 3px;
  box-shadow: 2px 2px 2px #d2e5d9;
  align-items: center;
}

div.File .file-box .icon {
  display: flex;
  width: 60px;
  height: 60px;
}

div.File .file-box .icon img {
  filter: invert(69%) sepia(34%) saturate(381%) hue-rotate(201deg) brightness(93%) contrast(94%);
  width: 100%;
}

div.File .file-box .info {
  width: calc(100% - 10px);
  margin-left: 10px;
  display: grid;
  grid-template-columns: 3fr 1fr;
}

div.File .file-box .info .title {
	grid-column: 1 / 2;
	grid-row: 1 / 2;
  font-size: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.File .file-box .info .size {
	grid-column: 1 / 2;
	grid-row: 2 / 3;
}

div.File .file-box .info .download {
	grid-column: 2 / 3;
	grid-row: 1 / 3;
  display: flex;
  justify-content: right;
  align-items: flex-end;
}


@media (max-width: 480px) {
  div.File .file-box .icon {
    display: none;
  }

  div.File .file-box .info .title {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }
  
  div.File .file-box .info .size {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
  }

  div.File .file-box .info .download {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  

  div.File .file-box .info .title {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }
  
  div.File .file-box .info .size {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
  }

  div.File .file-box .info .download {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
  }
}