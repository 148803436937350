div.Upload {
  width: 100%;
}

div.Upload .info {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 5px 0;
}

div.Upload .info > div {
  font-size: 1.25rem;
  color: #6c797c;
}

div.Upload .info .name {
  box-sizing: border-box;
  padding-right: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}